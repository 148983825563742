import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginRoutingModule } from './login.routing.module';
import { LoginComponent } from './login/login.component';
import { AuxiliaresModule } from '../../auxiliares/auxiliares.module';
import { AuthService } from './auth.service';
import { LoginService } from './login.service';
import { StoreService } from './store.service';
import { LogoutService } from './logout.service';

@NgModule({
  declarations: [LoginComponent],
  imports: [CommonModule, AuxiliaresModule, LoginRoutingModule],
  providers: [AuthService, LoginService, StoreService, LogoutService],
})
export class LoginModule {}
